<template>
  <div>
    <a-modal
      v-model="visibleModalManageStudentClass"
      class="mod"
      :title="
        status.isEdit ? 'Form Edit Student Class' : 'Form Create Student Class'
      "
      :maskClosable="false"
      @cancel="toggleModalManageStudentClass"
      :destroyOnClose="true"
      centered
    >
      <form-student-class
        :status="status"
        :dataStudentClass="newStudentClass"
        @handle-change="handleChange"
      />
      <template slot="footer">
        <a-button
          size="large"
          key="back"
          @click="toggleModalManageStudentClass"
        >
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :loading="loadingActionModalManageStudentClass"
          @click="handleOkModalManageStudentClass"
        >
          {{ status.isEdit ? "Edit" : "Create" }} Now
        </a-button>
      </template>
    </a-modal>
    <a-modal
      v-model="visibleModalManageStudent"
      width="80%"
      :title="`Manage Student for ${manageStudentData.nama} ${
        dataSchoolYear.semester === 'Ganjil' ? '1st' : '2nd'
      } Semester ${dataSchoolYear.tahun}`"
      @cancel="toggleModalManageStudent"
      :destroyOnClose="true"
      centered
    >
      <manage-student :manageStudentData="manageStudentData" />
      <template slot="footer">
        <a-button
          class="d-none m-0"
          size="large"
          key="back"
          @click="toggleModalManageStudent"
        >
          Close
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex align-items center">
      <div class="ml-auto">
        <a-button
          @click.prevent="toggleModalManageStudentClass('add')"
          size="large"
          type="primary"
          ><a-icon type="plus" />Create Student Class</a-button
        >
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="studentClassColumns"
        :data-source="studentClassDataTable"
        :pagination="studentClassPagination"
        :loading="studentClassLoadingTable"
        bordered
      >
        <div
          class="d-flex flex-column"
          slot="action"
          slot-scope="value, record"
        >
          <a-button
            @click.prevent="toggleModalManageStudent('open', record)"
            class="mb-3 text-primary border border-primary"
            size="large"
          >
            <a-icon type="edit" />Manage Students
          </a-button>
          <a-button
            @click.prevent="toggleModalManageStudentClass('edit', record)"
            class="mb-3 text-warning border border-warning"
            size="large"
          >
            <a-icon type="edit" />Edit
          </a-button>
          <a-button
            @click.prevent="handleDeleteStudentClass(record)"
            class="text-danger border border-danger"
            size="large"
          >
            <a-icon type="delete" />Delete
          </a-button>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
const formStudentClass = () => import('@/components/app/Admin/SchoolYear/StudentClass/FormStudentClass')
const manageStudent = () => import('@/components/app/Admin/SchoolYear/StudentClass/FormManageStudent')

const studentClassColumns = [
  {
    title: 'Study Program',
    dataIndex: 'program',
    key: 'program',
    scopedSlots: { customRender: 'program' },
  },
  {
    title: 'Class',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Total Students',
    dataIndex: 'totalStudent',
    key: 'totalStudent',
    scopedSlots: { customRender: 'totalStudent' },
    align: 'center',
  },
  {
    title: 'Homeroom Teacher',
    dataIndex: 'teacher',
    key: 'teacher',
    scopedSlots: { customRender: 'teacher' },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]

export default {
  name: 'StudentClass',
  components: {
    formStudentClass,
    manageStudent,
  },
  props: {
    dataSchoolYear: Object,
  },
  data() {
    return {
      studentClassColumns,
      visibleModalManageStudentClass: false,
      loadingActionModalManageStudentClass: false,
      loadingDeleteStudentClass: false,
      studentClassLoadingTable: false,
      visibleModalManageStudent: false,
      studentClassDataTable: [],
      studentClassPagination: {},
      status: {
        isEdit: false,
        isCreate: false,
      },
      newStudentClass: {
        id_level: null,
        id_tahun_ajaran: null,
        id_program: '',
        id_guru: null,
        zoom_url: null,
        zoom_password: null,
        zoom_token: null,
      },
      idEdit: null,
      manageStudentData: {
        class: null,
      },
    }
  },
  methods: {
    handleChange(payload) {
      const { value, column } = payload
      console.log('parent', payload)
      this.newStudentClass[column] = value
    },
    toggleModalManageStudent(status, data) {
      this.visibleModalManageStudent = !this.visibleModalManageStudent
      if (!this.visibleModalManageStudent) {
        setTimeout(() => {
          this.fetchDataStudentClass()
          this.manageStudentData = {
            nama: null,
          }
        }, 500)
        return ''
      }

      if (status) {
        console.log(data)
        this.manageStudentData = data
      }
    },
    toggleModalManageStudentClass(status, data) {
      this.visibleModalManageStudentClass = !this.visibleModalManageStudentClass
      if (!this.visibleModalManageStudentClass) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newStudentClass = {
            simbol: null,
            id_level: null,
            id_tahun_ajaran: null,
            id_program: '',
            id_guru: null,
            zoom_url: null,
            zoom_password: null,
            zoom_token: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newStudentClass = {
            ...data,
            simbol: data.simbol,
            id_level: data.id_level,
            id_program: data.id_program || '',
            id_tahun_ajaran: data.id_tahun_ajaran,
            id_guru: data.id_guru,
            zoom_url: data.zoom_url,
            zoom_password: data.zoom_password,
            zoom_token: data.zoom_token,
          }
        } else if (status === 'add') {
          this.newStudentClass.id_tahun_ajaran = this.dataSchoolYear.id
        }
      }
    },
    handleOkModalManageStudentClass() {
      const content = `${this.status.isEdit ? 'Are you sure want to edit this student class?' : 'Are you sure want to create new student class'}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Warning',
          description: 'All fields are required',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>{content}</div>
          ),
          onOk: () => {
            this.loadingActionModalManageStudentClass = true
            const payload = this.status.isEdit ? {
              id: this.idEdit,
              dataClass: {
                ...this.newStudentClass,
                id_program: this.newStudentClass.id_program || null,
              },
            } : {
              dataClass: {
                ...this.newStudentClass,
                id_program: this.newStudentClass.id_program || null,
              },
            }
            this.$store.dispatch(`master/${this.status.isEdit ? 'EDIT' : 'POST'}_CLASS`, payload)
              .then(isSuccess => {
                this.loadingActionModalManageStudentClass = false
                this.toggleModalManageStudentClass()
                this.fetchDataStudentClass()
                if (isSuccess) {
                  const description = this.status.isEdit ? 'Student Class has been updated' : 'Student Class has been created'
                  this.$notification.success({
                    message: 'Success',
                    description,
                  })
                } else {
                  const description = this.status.isEdit ? 'Student Class has not been updated' : 'Student Class has not been created'
                  this.$notification.error({
                    message: 'Failed',
                    description,
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Edit Now' : 'Create Now',
          cancelText: 'Cancel',
        })
      }
    },
    handleDeleteStudentClass(data) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to delete this student class?</div>
        ),
        onOk: () => {
          this.studentClassLoadingTable = true
          this.loadingDeleteStudentClass = true
          this.$store.dispatch('master/DELETE_CLASS', {
            id: data.key,
          })
            .then(isSuccess => {
              this.fetchDataStudentClass()
              this.loadingDeleteStudentClass = false
              if (isSuccess) {
                this.$notification.success({
                  message: 'Success',
                  description:
                    'Student Class has been deleted',
                })
              } else {
                this.$notification.error({
                  message: 'Failed',
                  description: 'Student Class has not been deleted',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeletestudentClass = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
        cancelText: 'Cancel',
      })
    },
    fetchDataStudentClass(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.studentClassLoadingTable = true
      this.$store.dispatch('studentClass/FETCH_STUDENT_CLASS', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy, schoolYearId: this.dataSchoolYear.id })
        .then(res => {
          this.studentClassLoadingTable = false
          // const pagination = { ...this.pagination }
          // pagination.total = res.total
          // this.pagination = pagination
          // console.log(res)
          this.studentClassDataTable = res.map(el => {
            return {
              ...el,
              key: el.id,
              program: el.program ? el.program.nama : '-',
              nama: el.tingkat + '-' + el.simbol,
              teacher: el.guru ? el.guru.nama : '-',
              totalStudent: el.kelas_tahun_ajarans.length,
            }
          })
        })
    },
  },
  mounted() {
    this.fetchDataStudentClass()
  },
  computed: {
    isValidateForm() {
      if (this.newStudentClass.simbol && this.newStudentClass.id_level && this.newStudentClass.id_guru) {
        return false
      }
      return true
    },
  },
}
</script>

<style>
</style>
